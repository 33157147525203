import React, { useState } from "react";
import Headpage from "../../component/Headpage";
import Headerimage from "../../assets/night.jpg";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./login.css";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();

  const submit = async (e) => {
    e.preventDefault();

    if (!username || !password) {
      setError("Please enter the required credintials");
      return;
    }

    if (password.length < 8) {
      setError("The password must be at least 8 letters");
      return;
    }

    setLoading(true);
    try {
      const url = "https://tourism-api.basilalasadi.com/api/Authenticate/login";
      const response = await axios.post(url, {
        Username: username,
        Password: password,
      });
      if (response.data.success) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("role", response.data.UserRoles[0]);
        toast.success("Logged in successfully!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      console.error("An error while logging in", error);
      setError("An error occured. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Headpage title="Login Up" image={Headerimage}></Headpage>
      <section className="login">
        <div className="container login-container">
          <form onSubmit={submit}>
            <div className="input-box">
              <input
                type="text"
                placeholder={t("logInPage.userName")}
                onChange={(e) => setUsername(e.target.value)}
                value={username}
              />
            </div>
            <div className="input-box">
              <input
                type="password"
                placeholder={t("logInPage.password")}
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </div>
            {error && <p className="error">{error}</p>}
            <button disabled={loading} className="details-btn">
              {loading ? t("logInPage.loggingIn") : t("logInPage.logIn")}
            </button>
          </form>
          <p>
            <Link to="/resetPass">Reset Password</Link>
          </p>
        </div>
      </section>
    </>
  );
};

export default Login;
