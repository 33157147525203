import React from "react";
import Headerhome from "../Headerhome";
import { CiBadgeDollar } from "react-icons/ci";
import { TbCalendarTime } from "react-icons/tb";
import { RiCustomerService2Line } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import "./travel.css";

const TravelWithUs = () => {
  const { t } = useTranslation();

  return (
    <section className="travelWithUs">
      <Headerhome title={t("headerHomeRecommendation.title")}>
        {t("headerHomeRecommendation.paragraph")}
      </Headerhome>
      <div className="travelWithUs-container container">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div
              className="box"
              data-aos="fade-up"
              data-aos-offset="100"
              data-aos-duration="900"
              data-aos-delay="200"
            >
              <div className="icon">
                <CiBadgeDollar />
              </div>
              <div className="text-box">
                <h5>{t("whyTravelWithUs.feature1.title")}</h5>
                <p>{t("whyTravelWithUs.feature1.description")}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div
              className="box"
              data-aos="fade-up"
              data-aos-offset="100"
              data-aos-duration="900"
              data-aos-delay="300"
            >
              <div className="icon">
                <TbCalendarTime />
              </div>
              <div className="text-box">
                <h5>{t("whyTravelWithUs.feature2.title")}</h5>
                <p>{t("whyTravelWithUs.feature2.description")}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div
              className="box"
              data-aos="fade-up"
              data-aos-offset="100"
              data-aos-duration="900"
              data-aos-delay="400"
            >
              <div className="icon">
                <RiCustomerService2Line />
              </div>
              <div className="text-box">
                <h5>{t("whyTravelWithUs.feature3.title")}</h5>
                <p>{t("whyTravelWithUs.feature3.description")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TravelWithUs;
