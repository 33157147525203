import React, { useEffect, useState } from "react";
import Topbar from "../../component/topbar/Topbar";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

const languages = [
  { code: "en", lang: "English", Id: 1 },
  { code: "it", lang: "Italy", Id: 2 },
];

const isValidLocation = (location) => location.trim() !== "";
const isValidtitle = (title) => title.trim() !== "";
const isValidDesc = (desc) => desc.trim() !== "";
const isValidPrice = (price) => price.trim() !== "";
const isValidLanguage = (language) => language.trim() !== "";

const AddLanguage = () => {
  const [loading, setLoading] = useState(false);
  const [selectedMainImage, setSelectedMainImage] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [otherImages, setOtherImages] = useState([]);
  const [lang, setLang] = useState("");
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [price, setPrice] = useState("");
  const [fromDate, setfromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [location, setLocation] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || languages[0].code
  );
  const [error, setError] = useState("");

  const [titleError, setTitleError] = useState("");
  const [descError, setDescError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [langError, setLangError] = useState("");
  const params = useParams();
  const navigate = useNavigate();

  const submit = async (e) => {
    e.preventDefault();

    const titleIsValid = isValidtitle(title);
    const descIsValid = isValidDesc(desc);
    const PriceIsValid = isValidPrice(price);
    const languageIsValid = isValidLanguage(lang);
    const locationIsValid = isValidLocation(location);

    setLangError(languageIsValid ? "" : "Please select a language.");
    setTitleError(
      titleIsValid ? "" : "Title is required. Please enter a title"
    );
    setDescError(descIsValid ? "" : "Description is required.");
    setPriceError(
      PriceIsValid ? "" : "Price is required and should be a valid number."
    );
    setLocationError(
      locationIsValid ? "" : "Location is required. Please enter a location."
    );

    const formData = new FormData();
    formData.append("LanguageId", lang);
    formData.append("mainImage", selectedMainImage);
    formData.append("Title", title);
    formData.append("Description", desc);
    formData.append("mainVideo", selectedVideo);
    formData.append("CostPerPerson", price);
    formData.append("fromDate", fromDate);
    formData.append("toDate", toDate);
    formData.append("Location", location);
    formData.append("TripId", params.id);
    for (let i = 0; i < otherImages.length; i++) {
      formData.append("OtherImages", otherImages[i]);
    }

    const selectedLanguageObject = languages.find(
      (lang) => lang.code === selectedLanguage
    );
    if (!selectedLanguageObject) {
      console.error("Selected language not found in languages list.");
      return;
    }
    setLoading(true);
    const apiUrl = `https://tourism-api.basilalasadi.com/api/Trip/AddTripTranslation`;
    if (
      titleIsValid &&
      descIsValid &&
      locationIsValid &&
      PriceIsValid &&
      languageIsValid
    ) {
      try {
        const response = await axios.post(apiUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.data.success) {
          toast.success("Language Added successfully!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        setLang("");
        setTitle("");
        setDesc("");
        setPrice("");
        setfromDate("");
        setToDate("");
        setLocation("");
        setSelectedMainImage(null);
        setSelectedVideo(null);
        setOtherImages(null);
        navigate("/dashboard/addedTrips");
      } catch (error) {
        // console.error("Error uploading Data:", error);
        setError("Trip translation added before");
      } finally {
        setLoading(false);
      }
    }
  };

  const getTripsDetails = async () => {
    try {
      const selectedLanguageObject = languages.find(
        (lang) => lang.code === selectedLanguage
      );
      if (!selectedLanguageObject) {
        console.error("Selected language not found in languages list.");
        return;
      }
      await axios.post(
        "https://tourism-api.basilalasadi.com/api/Trip/SearchTrips",
        {
          TripsId: params.id,
        }
      );
    } catch (error) {
      console.error("Error sending language code:", error);
    }
  };

  useEffect(() => {
    getTripsDetails();
  }, []);

  return (
    <div className="tripsControl">
      <Topbar />
      <div className=" tripsControl-container container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="content">
              <h2>Add Language:</h2>
              <form onSubmit={submit}>
                <div className="input-box d-flex g-2 mb-4">
                  <div>
                    <label htmlFor="image">Location</label>
                    <input
                      type="text"
                      placeholder="Enter A Location"
                      name="image"
                      id="myImageInput"
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                    />
                    {locationError && (
                      <p className="text-danger">{locationError}</p>
                    )}
                  </div>
                  <div>
                    <label> Trips's Title</label>
                    <input
                      type="text"
                      placeholder="Enter A Title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                    {titleError && <p className="text-danger">{titleError}</p>}
                  </div>
                </div>
                <div className="input-box d-flex g-2 mb-4">
                  <div>
                    <label>Cost Per Person</label>
                    <input
                      className="priceInput"
                      type="text"
                      placeholder="Enter The Price"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                    {priceError && <p className="text-danger">{priceError}</p>}
                  </div>
                  <div>
                    <label>Trip's Description</label>
                    <input
                      type="text"
                      placeholder="Enter A Description"
                      value={desc}
                      onChange={(e) => setDesc(e.target.value)}
                    />
                    {descError && <p className="text-danger">{descError}</p>}
                  </div>
                </div>
                <div className="input-box d-flex">
                  <div className="div selection-box mb-4 input">
                    <label>Choose A Language</label>
                    <select
                      className="custom-select w-100 areaSelect p-3"
                      id="inputGroupSelect01"
                      value={lang}
                      onChange={(e) => setLang(e.target.value)}
                    >
                      <option>Language</option>
                      <option value={1} disabled>
                        EN
                      </option>
                      <option value={2}>IT</option>
                    </select>
                    {langError && <p className="text-danger">{langError}</p>}
                  </div>
                </div>
                {error && <p className="error">{error}</p>}
                <button
                  type="submit"
                  disabled={loading}
                  className="details-btn"
                >
                  {loading ? "Adding Language..." : "Add Language"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddLanguage;
