import React, { useEffect } from "react";
import Headpage from "../../component/Headpage";
import Headerimage from "../../assets/night.jpg";
import Historyimage from "../../assets/image9.jpg";
import Visionimage from "../../assets/image15.jpg";
import Missionimage from "../../assets/image7.jpg";
import Aos from "aos";
import Headerhome from "../../component/Headerhome";
import { useTranslation } from "react-i18next";
import "./about.css";

const About = () => {
  const { t } = useTranslation();

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <Headpage
        title={t("headerHomeAbout.title")}
        image={Headerimage}
      ></Headpage>
      <section className="about">
        <Headerhome title={t("headerHomeAbout.title")}>
          {t("headerHomeAbout.paragraph")}
        </Headerhome>
        <div className="about-container container pt-5 pb-5">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="about-history ">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div
                      className="text-box"
                      data-aos="fade-down"
                      data-aos-offset="100"
                      data-aos-delay="200"
                      data-aos-duration="900"
                    >
                      <h1> {t("aboutPage.aboutHistory.head")}</h1>
                      <p>{t("aboutPage.aboutHistory.paragraph")}</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div
                      className="img-box"
                      data-aos="fade-up"
                      data-aos-offset="100"
                      data-aos-delay="200"
                      data-aos-duration="900"
                    >
                      <img src={Historyimage} alt="History imag" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="about-vision">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div
                      className="img-box"
                      data-aos="fade-up"
                      data-aos-offset="100"
                      data-aos-delay="200"
                      data-aos-duration="900"
                    >
                      <img src={Visionimage} alt="vision imag" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div
                      className="text-box"
                      data-aos="fade-down"
                      data-aos-offset="100"
                      data-aos-delay="200"
                      data-aos-duration="900"
                    >
                      <h1>{t("aboutPage.aboutVision.head")}</h1>
                      <p>{t("aboutPage.aboutVision.paragraph")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="about-mission ">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div
                      className="text-box"
                      data-aos="fade-down"
                      data-aos-offset="100"
                      data-aos-delay="200"
                      data-aos-duration="900"
                    >
                      <h1>{t("aboutPage.aboutMission.head")}</h1>
                      <p>{t("aboutPage.aboutMission.paragraph")}</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div
                      className="imag-box"
                      data-aos="fade-up"
                      data-aos-offset="100"
                      data-aos-delay="200"
                      data-aos-duration="900"
                    >
                      <img src={Missionimage} alt="mission imag" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
