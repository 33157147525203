import React from "react";
import Headerhome from "../Headerhome";
import { useTranslation } from "react-i18next";
import Image1 from "../../assets/image6.jpg";
import Image2 from "../../assets/OIP.jpg";
import Image3 from "../../assets/image16.jpg";
import { Link } from "react-router-dom";
import "./trips.css";

const Trips = () => {
  const { t } = useTranslation();
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <section className="trips">
      <Headerhome title={t("headerHomeTrips.title")}>
        {t("headerHomeTrips.paragraph")}
      </Headerhome>
      <div className="container trips-container">
        <div
          className="card"
          data-aos="fade-up"
          data-aos-offset="100"
          data-aos-duration="900"
          data-aos-delay="200"
        >
          <img src={Image1} className="card-img-top" alt={"recentTrips"} />
          <div className="card-body">
            <h3 className="card-title">{t("recentTrips.trip1.title")}</h3>
            <p className="card-text">{t("recentTrips.trip1.description")}</p>
          </div>
          <a
            href="https://api.whatsapp.com/send?phone=+201151115204&text=Hello%2C%20I'm%20interested%in%your%product"
            target="__blank"
            className="details-btn"
          >
            {t("recentTrips.trip1.button")}
          </a>
        </div>
        <div
          className="card"
          data-aos="fade-up"
          data-aos-offset="100"
          data-aos-duration="900"
          data-aos-delay="300"
        >
          <img src={Image2} className="card-img-top" alt={"recentTrips"} />
          <div className="card-body">
            <h3 className="card-title">{t("recentTrips.trip2.title")}</h3>
            <p className="card-text">{t("recentTrips.trip2.description")}</p>
          </div>
          <a
            href="https://api.whatsapp.com/send?phone=+201151115204&text=Hello%2C%20I'm%20interested%in%your%product"
            target="__blank"
            className="details-btn btn"
          >
            {t("recentTrips.trip2.button")}
          </a>
        </div>
        <div
          className="card"
          data-aos="fade-up"
          data-aos-offset="100"
          data-aos-duration="900"
          data-aos-delay="400"
        >
          <img src={Image3} className="card-img-top" alt={"recentTrips"} />
          <div className="card-body">
            <h3 className="card-title">{t("recentTrips.trip3.title")}</h3>
            <p className="card-text">{t("recentTrips.trip3.description")}</p>
          </div>
          <a
            href="https://api.whatsapp.com/send?phone=+201151115204&text=Hello%2C%20I'm%20interested%in%your%product"
            target="__blank"
            className="details-btn"
          >
            {t("recentTrips.trip3.button")}
          </a>
        </div>
      </div>
      <div className="btn-holder text-center">
        <Link to={"/services"} onClick={handleClick}>
          <button>{t("recentTrips.trip1.seeAllbtn")}</button>
        </Link>
      </div>
    </section>
  );
};

export default Trips;
