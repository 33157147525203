import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/grande-tourism.png";
import LanguageSelector from "../languageSelector/LanguageSelector";
import "./topbar.css";

const Topbar = () => {
  return (
    <nav className="navbar dashboardNavbar navbar-expand-lg">
      <div className="container container-fluid">
        <Link className="navbar-brand logo" to="/">
            <img src={Logo} alt="Trips Logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  className=" nav-link active p-2"
                  aria-current="page"
                  to="/dashboard/tripsControl"
                >
                  Add Trips
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link p-2" to="/dashboard/addedTrips">
                  Trips
                </Link>
              </li>
              <li>
                <LanguageSelector />
              </li>
            </ul>
          </div>
        </div>
      </nav>
  );
};

export default Topbar;
