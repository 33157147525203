import axios from "axios";

const ErrorInterceptor = axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response ? error.response.status : null;
    if (status === 401) {
      console.log("Unauthorized access");
      localStorage.removeItem("token");
      window.location.href = "/Login";
    } else if (status === 403) {
      console.log("Unauthorized");
      localStorage.removeItem("token");
      window.location.href = "/Login";
    } else if (status === 404) {
      console.log("Not found");
    } else {
      console.error("An error occurred:", error);
    }
    return Promise.reject(error);
  }
);

export default ErrorInterceptor;
