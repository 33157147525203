import React, { useEffect, useState } from "react";
import Headerimage from "../../assets/night.jpg";
import Headpage from "../../component/Headpage";
import Headerhome from "../../component/Headerhome";
import { useTranslation } from "react-i18next";
import Aos from "aos";
import { Link } from "react-router-dom";
import axios from "axios";
import { CiLocationOn } from "react-icons/ci";
import "./services.css";

const languages = [
  { code: "en", lang: "English", Id: 1 },
  { code: "it", lang: "Italy", Id: 2 },
];

const maxLength = 100;

const Services = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || languages[0].code
  );
  const { t } = useTranslation();
  const [addedTrips, setAddedTrips] = useState([]);

  const getAllAddedTrips = async () => {
    try {
      const selectedLanguageObject = languages.find(
        (lang) => lang.code === selectedLanguage
      );
      if (!selectedLanguageObject) {
        console.error("Selected language not found in languages list.");
        return;
      }
      const response = await axios.post(
        "https://tourism-api.basilalasadi.com/api/Trip/SearchTrips",
        { languageId: selectedLanguageObject.Id }
      );
      setAddedTrips(response.data.Items);
    } catch (error) {
      console.error("Error sending language code:", error);
    }
  };

  useEffect(() => {
    getAllAddedTrips();
    Aos.init();
  }, []);

  return (
    <>
      <Headpage
        title={t("headerHomeServices.title")}
        image={Headerimage}
      ></Headpage>
      <section className="services">
        <Headerhome title={t("headerHomeTrips.title")}>
          {t("headerHomeTrips.paragraph")}
        </Headerhome>
        <div className="container trips-container">
          {addedTrips.length > 0 ? (
            addedTrips.map((addedTrip) => (
              <div
                key={addedTrip.TripId}
                className="card"
                data-aos="fade-up"
                data-aos-offset="100"
                data-aos-delay="200"
                data-aos-duration="900"
              >
                <img
                  src={addedTrip.MainImageUrl}
                  className="card-img-top"
                  alt={"recentTrips"}
                />
                <div className="card-body">
                  <h5 className="card-title">
                    <CiLocationOn /> {addedTrip.Location}
                  </h5>
                  <h4 className="card-title">{addedTrip.Title}</h4>
                  <p className="card-text">
                    {addedTrip.Description &&
                    addedTrip.Description.length > maxLength
                      ? `${addedTrip.Description.substring(0, maxLength)}...`
                      : addedTrip.Description}
                  </p>
                  <small className="card-text d-block mb-4">
                    From <span>${addedTrip.CostPerPerson}</span> Per Person
                  </small>
                  <Link
                    to={`/services/${addedTrip.TripId}`}
                    className="details-btn"
                  >
                    {t("recentTrips.trip1.button")}
                  </Link>
                </div>
              </div>
            ))
          ) : (
            <div className="notFound">
              <h1>No Trips Found :(</h1>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Services;
