import React, { useEffect, useState } from "react";
import { AiOutlineHome, AiOutlineClose } from "react-icons/ai";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { MdOutlineHomeRepairService } from "react-icons/md";
import { IoMdContact } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/grande-tourism.png";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../languageSelector/LanguageSelector";
import axios from "axios";
import "./navbar.css";

const languages = [
  { code: "en", lang: "English", Id: 1 },
  { code: "it", lang: "Italy", Id: 2 },
];

const Navbar = (navbar) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [data, setdata] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || languages[0].code
  );

  const submitLanguageValue = async () => {
    try {
      const selectedLanguageObject = languages.find(
        (lang) => lang.code === selectedLanguage
      );
      if (!selectedLanguageObject) {
        console.error("Selected language not found in languages list.");
        return;
      }
      const response = await axios.post(
        "https://tourism-api.basilalasadi.com/api/Trip/SearchTrips",
        { languageId: selectedLanguageObject.Id }
      );
      setdata(response.data.items);
    } catch (error) {
      console.error("Error sending language code:", error);
    }
  };

  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    submitLanguageValue();
  }, []);

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container container-fluid">
        <Link className="navbar-brand logo" to="/" onClick={handleClick}>
          <img src={Logo} alt="Trips-Logo" />
        </Link>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          {!localStorage.getItem("token") ? (
            <ul
              className="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center"
              key={navbar.Key}
            >
              <AiOutlineClose className="close" />
              <li className="nav-item">
                <Link
                  onClick={handleClick}
                  className=" nav-link active p-2"
                  aria-current="page"
                  to="/"
                >
                  <AiOutlineHome className="icon active" />
                  {t("navbar.home")}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link p-2"
                  to="/about"
                  onClick={handleClick}
                >
                  <BsFillInfoCircleFill className="icon" />
                  {t("navbar.about")}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link p-2"
                  to="/services"
                  onClick={handleClick}
                >
                  <MdOutlineHomeRepairService className="icon" />
                  {t("navbar.service")}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link p-2"
                  to="/contact"
                  onClick={handleClick}
                >
                  <IoMdContact className="icon" />
                  {t("navbar.contact")}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link sign"
                  to="/login"
                  onClick={handleClick}
                >
                  {t("navbar.log in")}
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link
                  className="nav-link sign"
                  to="/signup"
                  onClick={handleClick}
                >
                  {t("navbar.sign up")}
                </Link>
              </li> */}
            </ul>
          ) : (
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center">
              <AiOutlineClose className="close" />
              <li className="nav-item">
                <Link
                  className=" nav-link active p-2"
                  aria-current="page"
                  to="/"
                  onClick={handleClick}
                >
                  <AiOutlineHome className="icon active" />
                  {t("navbar.home")}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link p-2"
                  to="/about"
                  onClick={handleClick}
                >
                  <BsFillInfoCircleFill className="icon" />
                  {t("navbar.about")}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link p-2"
                  to="/services"
                  onClick={handleClick}
                >
                  <MdOutlineHomeRepairService className="icon" />
                  {t("navbar.service")}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link p-2"
                  to="/contact"
                  onClick={handleClick}
                >
                  <IoMdContact className="icon" />
                  {t("navbar.contact")}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link p-2"
                  to="/dashboard/tripsControl"
                  onClick={handleClick}
                >
                  <IoMdContact className="icon" />
                  {t("navbar.dashboard")}
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link sign" to="/login" onClick={logout}>
                  {t("navbar.log out")}
                </Link>
              </li>
            </ul>
          )}
        </div>
        <div className="langList">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <LanguageSelector />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
