import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import "./style.css";

const languages = [
  { code: "en", lang: "English", Id: 1 },
  { code: "it", lang: "Italy", Id: 2 },
];

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [data, setdata] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || languages[0].code
  );
  const [otherLanguageData, setOtherLanguageData] = useState([]);
  
  const fetchOtherLanguageData = async () => {
    try {
      localStorage.setItem("selectedLanguage", selectedLanguage);
      const response = await axios.get(
        "https://tourism-api.basilalasadi.com/api/Languages"
      );
      setOtherLanguageData(response.data);
    } catch (error) {
      console.error("Error fetching language data:", error);
    }
  };

  const sendLanguageValue = async () => {
    try {
      const selectedLanguageObject = languages.find(
        (lang) => lang.code === selectedLanguage
      );
      console.log(selectedLanguage);
      if (!selectedLanguageObject) {
        console.error("Selected language not found in languages list.");
        return;
      }
      const response = await axios.post(
        "https://tourism-api.basilalasadi.com/api/Trip/SearchTrips",
        { languageId: selectedLanguageObject.Id }
      );
      setdata(response.data.Items);
    } catch (error) {
      console.error("Error sending language code:", error);
    }
  };

  const changeLanguage = (e) => {
    const newLanguage = e.target.value;
    i18n.changeLanguage(newLanguage);
    setSelectedLanguage(newLanguage);
    sendLanguageValue(newLanguage);
    window.location.reload();
  };

  useEffect(() => {
    fetchOtherLanguageData();
    localStorage.setItem("selectedLanguage", selectedLanguage.toLowerCase());
  }, [selectedLanguage]);

  return (
    <div className="btn-container languageSelector">
      <select onChange={changeLanguage} value={selectedLanguage.toLowerCase()}>
        {otherLanguageData.map((lang) => (
          <option key={lang.Id} value={lang.LanguageCode.toLowerCase()}>
            {lang.LanguageCode.toLowerCase()}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LanguageSelector;
