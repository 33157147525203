import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "./resetPass.css";

const ResetPass = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const submit = async (e) => {
    e.preventDefault();

    if (!username || !password) {
      setError("Please enter the required credintials");
      return;
    }

    if (password.length < 8) {
      setError("The password must be at least 8 letters");
      return;
    }

    if (newPassword.length < 8) {
      setError("The new Password must be at least 8 letters");
      return;
    }

    setLoading(true);

    try {
      const url =
        "https://tourism-api.basilalasadi.com/api/Authenticate/changepassword";
      const response = await axios.post(url, {
        Username: username,
        CurrentPassword: password,
        NewPassword: newPassword,
      });
      console.log(response.status)
      if (response.status === 200) {
        toast.success("Password Reseted successfully!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/login");
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      console.error("An error while logging in", error);
      setError("An error occured. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="resetPass">
      <div className="container resetPass-container">
        <form onSubmit={submit}>
          <div className="input-box">
            <input
              type="text"
              placeholder={"User Name"}
              onChange={(e) => setUsername(e.target.value)}
              value={username}
            />
          </div>
          <div className="input-box">
            <input
              type="password"
              placeholder="Current Password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
          </div>
          <div className="input-box">
            <input
              type="password"
              placeholder="New Password"
              onChange={(e) => setNewPassword(e.target.value)}
              value={newPassword}
            />
          </div>
          {error && <p className="error">{error}</p>}
          <button disabled={loading} className="details-btn">
            {loading ? "Resetting Password" : "Reset"}
          </button>
        </form>
      </div>
    </section>
  );
};

export default ResetPass;
