import React, { useEffect, useState } from "react";
import Topbar from "../../component/topbar/Topbar";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

const isValidLocation = (location) => location.trim() !== "";
const isValidtitle = (title) => title.trim() !== "";
const isValidDesc = (desc) => desc.trim() !== "";
const isValidPrice = (price) => price !== "";
const isValidFromDate = (fromDate) => fromDate.trim() !== "";
const isValidToDate = (toDate) => toDate.trim() !== "";
const isValidLanguage = (language) => language;
const isValidMainImage = (mainImage) => mainImage;
const isValidVideo = (video) => video;
const isValidOtherImages = (otherImages) => otherImages;

const UpdateTrip = () => {
  const [loading, setLoading] = useState(false);
  const [selectedMainImage, setSelectedMainImage] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [otherImages, setOtherImages] = useState([]);
  const [lang, setLang] = useState("");
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [price, setPrice] = useState("");
  const [fromDate, setfromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [location, setLocation] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || languages[0].code
  );

  const [titleError, setTitleError] = useState("");
  const [descError, setDescError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [fromDateError, setfromDateError] = useState("");
  const [toDateError, setToDateError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [langError, setLangError] = useState("");
  const [selectedMainImageError, setSelectedMainImageError] = useState("");
  const [selectedVideoError, setSelectedVideoError] = useState("");
  const [otherImagesError, setOtherImagesError] = useState("");

  const navigate = useNavigate();
  const params = useParams();

  const handleMainImageChange = (event) => {
    setSelectedMainImage(event.target.files[0]);
  };

  const handleVideoChange = (event) => {
    setSelectedVideo(event.target.files[0]);
  };

  const handleMultibleImages = (event) => {
    setOtherImages(event.target.files);
  };

  const submit = async (e) => {
    e.preventDefault();

    const titleIsValid = isValidtitle(title);
    const descIsValid = isValidDesc(desc);
    const PriceIsValid = isValidPrice(price);
    const fromDateIsValid = isValidFromDate(fromDate);
    const languageIsValid = isValidLanguage(lang);
    const toDateIsValid = isValidToDate(toDate);
    const locationIsValid = isValidLocation(location);
    const mainImageIsValid = isValidMainImage(selectedMainImage);
    const mainVideoIsValid = isValidVideo(selectedVideo);
    const otherImagesIsValid = isValidOtherImages(otherImages);

    setLangError(languageIsValid ? "" : "Please select a language.");
    setTitleError(
      titleIsValid ? "" : "Title is required. Please enter a title"
    );
    setDescError(descIsValid ? "" : "Description is required.");
    setPriceError(
      PriceIsValid ? "" : "Price is required and should be a valid number."
    );
    setfromDateError(
      fromDateIsValid ? "" : "Date is required. Please select a valid date."
    );
    setToDateError(
      toDateIsValid ? "" : "Date is required. Please select a valid date."
    );
    setLocationError(
      locationIsValid ? "" : "Location is required. Please enter a location."
    );
    setSelectedMainImageError(
      mainImageIsValid ? "" : "Image is required. Please select an image."
    );
    setSelectedVideoError(
      mainVideoIsValid ? "" : "Video is required. Please select a valid video."
    );
    setOtherImagesError(
      otherImagesIsValid
        ? ""
        : "Images are required. Please select valid images."
    );

    const formData = new FormData();
    formData.append("LanguageId", lang);
    formData.append("mainImage", selectedMainImage);
    formData.append("Title", title);
    formData.append("Description", desc);
    formData.append("mainVideo", selectedVideo);
    formData.append("CostPerPerson", price);
    formData.append("fromDate", fromDate);
    formData.append("toDate", toDate);
    formData.append("Location", location);
    formData.append("TripId", params.id);
    for (let i = 0; i < otherImages.length; i++) {
      formData.append("OtherImages", otherImages[i]);
    }

    const selectedLanguageObject = languages.find(
      (lang) => lang.code === selectedLanguage
    );
    if (!selectedLanguageObject) {
      console.error("Selected language not found in languages list.");
      return;
    }
    setLoading(true);
    const apiUrl = `https://tourism-api.basilalasadi.com/api/Trip/UpdateTrip`;
    if (
      titleIsValid &&
      descIsValid &&
      PriceIsValid &&
      fromDateIsValid &&
      toDateIsValid &&
      languageIsValid &&
      mainImageIsValid &&
      otherImagesIsValid &&
      mainVideoIsValid &&
      locationIsValid
    ) {
      try {
        const response = await axios.put(apiUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.data.success) {
          toast.success("Trip Updated successfully!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        setLang("");
        setTitle("");
        setDesc("");
        setPrice("");
        setfromDate("");
        setToDate("");
        setLocation("");
        setSelectedMainImage(null);
        setSelectedVideo(null);
        setOtherImages(null);
        navigate("/dashboard/addedTrips");
      } catch (error) {
        console.error("Error uploading Data:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const languages = [
    { code: "en", lang: "English", Id: 1 },
    { code: "it", lang: "Italy", Id: 2 },
  ];

  const getTripsDetails = async () => {
    try {
      const selectedLanguageObject = languages.find(
        (lang) => lang.code === selectedLanguage
      );
      if (!selectedLanguageObject) {
        console.error("Selected language not found in languages list.");
        return;
      }
      const response = await axios.post(
        "https://tourism-api.basilalasadi.com/api/Trip/SearchTrips",
        {
          TripsId: params.id,
          LanguageId: selectedLanguageObject.Id,
        }
      );
      if (Array.isArray(response.data.Items)) {
        response.data.Items.forEach((trip) => {
          setTitle(trip.Title);
          setDesc(trip.Description);
          setPrice(trip.CostPerPerson);
          setLang(trip.languageId);
          setLocation(trip.Location);
        });
      } else {
        console.error("Response data is not an array:", response.data);
      }
    } catch (error) {
      console.error("Error sending language code:", error);
    }
  };

  useEffect(() => {
    getTripsDetails();
  }, []);

  return (
    <div className="tripsControl">
      <Topbar />
      <div className=" tripsControl-container container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="content">
              <h2>Add Trips:</h2>
              <form onSubmit={submit}>
                <div className="input-box d-flex g-2 mb-4">
                  <div>
                    <label htmlFor="image">Location</label>
                    <input
                      type="text"
                      placeholder="Enter An Location"
                      name="image"
                      id="myImageInput"
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                    />
                    {locationError && (
                      <p className="text-danger">{locationError}</p>
                    )}
                  </div>
                  <div>
                    <label> Trips's Title</label>
                    <input
                      type="text"
                      placeholder="Enter A Title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                    {titleError && <p className="text-danger">{titleError}</p>}
                  </div>
                </div>
                <div className="input-box d-flex g-2 mb-4">
                  <div>
                    <label>Cost Per Person</label>
                    <input
                      className="priceInput"
                      type="text"
                      placeholder="Enter The Price"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                    {priceError && <p className="text-danger">{priceError}</p>}
                  </div>
                  <div>
                    <label>Trip's Description</label>
                    <input
                      type="text"
                      placeholder="Enter A Description"
                      value={desc}
                      onChange={(e) => setDesc(e.target.value)}
                    />
                    {descError && <p className="text-danger">{descError}</p>}
                  </div>
                </div>
                <div className="input-box d-flex">
                  <div className="div selection-box mb-4 input">
                    <label>Choose A Language</label>
                    <select
                      className="custom-select w-100 areaSelect p-3"
                      id="inputGroupSelect01"
                      value={lang}
                      onChange={(e) => setLang(e.target.value)}
                      disabled
                    >
                      <option>Language</option>
                      <option value={1}>EN</option>
                      <option value={2}>IT</option>
                    </select>
                    {langError && <p className="text-danger">{langError}</p>}
                  </div>
                  <div>
                    <label htmlFor="image">Main Image</label>
                    <input
                      type="file"
                      placeholder="Enter An Image"
                      name="image"
                      id="myImageInput"
                      onChange={handleMainImageChange}
                    />
                    {selectedMainImageError && (
                      <p className="text-danger">{selectedMainImageError}</p>
                    )}
                  </div>
                </div>
                <div className="input-box d-flex">
                  <div>
                    <label>Start Date</label>
                    <input
                      type="time"
                      placeholder="Enter An fromDate "
                      value={fromDate}
                      onChange={(e) => setfromDate(e.target.value)}
                    />
                    {fromDateError && (
                      <p className="text-danger">{fromDateError}</p>
                    )}
                  </div>
                  <div>
                    <label>End Date</label>
                    <input
                      type="time"
                      placeholder="Enter An toDate "
                      value={toDate}
                      onChange={(e) => setToDate(e.target.value)}
                    />
                    {toDateError && (
                      <p className="text-danger">{toDateError}</p>
                    )}
                  </div>
                </div>
                <div className="input-box d-flex mt-5">
                  <div>
                    <label>Trip's Video</label>
                    <input
                      type="file"
                      placeholder="Enter A Video URL"
                      name="video"
                      onChange={handleVideoChange}
                    />
                    {selectedVideoError && (
                      <p className="text-danger">{selectedVideoError}</p>
                    )}
                  </div>
                  <div className="input-box">
                    <label htmlFor="">Gallery</label>
                    <input
                      name="image"
                      type="file"
                      placeholder="Enter An Image "
                      multiple
                      onChange={handleMultibleImages}
                    />
                    {otherImagesError && (
                      <p className="text-danger">{otherImagesError}</p>
                    )}
                  </div>
                </div>
                <button
                  type="submit"
                  disabled={loading}
                  className="details-btn"
                >
                  {loading ? "Updating..." : "Update Trip"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateTrip;
